/* dark mode*/
/* color variables */
:root {
  --white: #fff;
  --black: #222;
  --workspaceBg: #353535;
  --blue:#99AFFF;
  --lightBlue: #DFE5FC;
  --lightGray: #606060;
  --disableFontColor: var(--lightGray);
  --overlayBg: #00000061;
  --validationBg: #F6C065;
}
/* end color variables */

.dark .borderPrimaryColor,
.dark .btn-icon:active:hover{
  border-color: var(--blue);
}

.dark .Mui-disabled:hover{
  cursor: not-allowed;
}

.submenuBgDark{
  background-color: var(--black);
}

.dark .infoCard,
.dark .MuiFormControl-root.MuiTextField-root .MuiInputBase-root,
.dark .MuiFormControl-root.MuiTextField-root .MuiInputBase-root .MuiFilledInput-input{
  background-color: var(--workspaceBg);
}

.dark .validationMsg,
.dark .validationMsg.MuiAccordion-root, 
.dark .validationMsg.MuiAccordion-root .MuiAccordionDetails-root,
.dark .validationMsg.MuiAccordion-root .MuiAccordionDetails-root .MuiButton-root,
.dark .validationMsg .MuiAccordionSummary-content .MuiStack-root{
  background: var(--validationBg);
  color: var(--black);
  border-color: var(--validationBg);
}

.dark .validationMsg.MuiAccordion-root .MuiAccordionDetails-root .MuiButton-root:disabled{
  opacity: 1;
}

.dark .validationMsg .MuiTypography-root,
.dark .validationMsg.MuiAccordion-root .MuiAccordionSummary-expandIconWrapper{
  color: var(--black);
}

.dark .MuiBox-root.workspaceBg,
.dark .MuiFormControl-root.MuiTextField-root .MuiFilledInput-root.MuiInputBase-root.Mui-disabled{
  background-color: var(--workspaceBg);
}

.dark .MuiTypography-root.primary-color,
.dark .btn-icon:hover,
.dark .btn-icon:active:hover,
.dark .MuiSwitch-switchBase.Mui-checked,
.dark .MuiButtonBase-root.MuiButton-root.MuiButton-text:hover,
.dark .MuiButtonBase-root.MuiIconButton-root:hover,
.dark .MuiLink-root:hover .MuiTypography-root{
  color: var(--blue);
}

.dark .MuiAccordion-root,
.dark .MuiCollapse-root,
.dark .MuiPaper-root,
.dark .MuiTab-root,
.dark .MuiStack-root,
.dark .MuiButtonBase-root.MuiTab-root:hover,
.dark .MuiBox-root,
.dark .MuiListSubheader-root,
.dark .MuiTextField-root .MuiFilledInput-input,
.dark.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper,
.dark .MuiButton-root .label-box{
  background: var(--black);
  color: var(--white);
}

.dark .MuiTab-root.Mui-selected{
  color:var(--blue);
  border-bottom-color: var(--blue);
}

.dark .MuiDivider-root{
  background: var(--white);
}

.dark .MuiListItemButton-root:hover .MuiListItemIcon-root,
.dark .MuiListItemButton-root:hover .MuiTypography-root{
  color: var(--black);
}

.dark .MuiListItemButton-root:hover{
  background: var(--lightGray);
}

.dark .MuiIconButton-root,
.dark .MuiListItemButton-root,
.dark .MuiListItemIcon-root,
.dark .MuiButton-text,
.dark .MuiButtonBase-root.MuiMenuItem-root,
.dark .MuiPaper-root .MuiList-root .MuiMenuItem-root .MuiSvgIcon-root{
  color: var(--white);
  background: transparent;
}

.dark .MuiButtonBase-root.MuiButton-root:hover,
.dark .MuiSvgIcon-root.MuiSelect-icon,
.dark .MuiInputAdornment-root,
.dark .MuiFormControl-root.MuiTextField-root .MuiInputBase-root,
.dark .MuiTypography-root,
.dark .MuiAccordionSummary-expandIconWrapper,
.dark .MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-root .MuiSelect-select,
.dark .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input{  
  color: var(--white);
}

.dark .MuiButtonBase-root.MuiIconButton-root:disabled{
  background: var(--black);
  color: var(--disableFontColor);
}

.dark .MuiButtonBase-root.MuiButton-root:disabled{
  opacity: 0.4;
}

.dark .MuiButtonBase-root.MuiIconButton-root[type="Selected"],
.dark .MuiButtonBase-root.MuiToggleButton-root.Mui-selected,
.dark .MuiButtonBase-root.MuiToggleButton-root.Mui-selected:hover,
.dark .MuiButtonBase-root.MuiButton-root:hover .label-box,
.dark .MuiButtonBase-root.MuiButton-root:hover .label-box .MuiTypography-root{
  background-color: var(--blue);
  color: var(--black);
}

.dark .MuiButtonBase-root.MuiIconButton-root[type="Selected"]:hover,
.dark .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track,
.dark .MuiButtonBase-root.MuiToggleButton-root:hover{
  background-color: var(--lightBlue);
}

.dark .MuiButtonBase-root.MuiMenuItem-root:hover{
  background-color: var(--lightGray);
}

.dark .MuiListItemButton-root,
.dark .MuiListItemButton-root:hover{
  border-color: transparent;
}

.dark .MuiBox-root.overlay{
  background-color: var(--overlayBg);
}

.dark .MuiButtonBase-root.MuiButton-root{
  color: var(--black);
  background-color: var(--blue);
  border-color: var(--blue);

}

.dark .MuiButtonBase-root.MuiButton-root.MuiButton-text{
  background-color: transparent;
  color: var(--white);
}

.dark .MuiButtonBase-root.MuiButton-root.MuiButton-text:disabled,
.dark .MuiButtonBase-root.MuiTab-root.Mui-disabled{
  opacity: 0.3;
}

.dark .MuiButtonBase-root.MuiTab-root.Mui-disabled{
  color: var(--white);
}

.dark .MuiInputBase-input.MuiFilledInput-input.Mui-disabled{
  opacity: 0.3;
  background-color: var(--workspaceBg);
  -webkit-text-fill-color: var(--white);
}

.dark .MuiTextField-root .MuiInputBase-root:hover{
  border-bottom-color: var(--blue);
}

.dark .MuiFormControl-root.MuiTextField-root .MuiFilledInput-root.MuiInputBase-root.Mui-disabled:hover{
  border-color: var(--workspaceBg);
}

/*forms inputs*/
.dark .MuiInputBase-root.MuiInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl,
.dark .MuiFormControl-root.MuiTextField-root .Mui-focused.Mui-focused .MuiFilledInput-input,
.dark .MuiToggleButtonGroup-root {
  background-color: var(--workspaceBg);
  color: var(--white);
}

.dark .MuiFormControl-root.MuiTextField-root .Mui-focused.Mui-focused,
.dark .MuiButton-root .label-box{
  border-color: var(--blue);
}
/*end*/

.dark.MuiPopover-root.MuiModal-root .MuiMenu-paper{
  border: 1px solid var(--white);
}