@import "darkMode.css";

body {
  margin: 0;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursor-default {
	cursor: default;
}
.cursor-all-scroll {
	cursor: all-scroll;
}
.cursor-crosshair {
	cursor: crosshair;
}

.adsk-viewing-viewer{
    background: none !important;
}

.overflowHidden{
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.overflowHidden::-webkit-scrollbar {
  width: 0 !important
  }

.workspaceBg{
  background-color: #F8F8FC;
}

.submenuBg{
  background-color: #fff;
}

.caseNameCol{
  font-weight: 600;
}

/* Removing arrows input type number Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Removing arrows input type number Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.selectedBox + .selectedBox{
  border-top: 0;
}

.simulationBox:last-child {
  border-bottom-width: 0;
}

